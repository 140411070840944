/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =============================================================================
   ~Initiatives View
   ========================================================================== */
/**** Initiatives View ****/
.pane-initiatives-initiatives {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 20px; }
  @media screen and (min-width: 768px) {
    .pane-initiatives-initiatives .view-content {
      display: flex;
      flex-wrap: wrap; }
      .pane-initiatives-initiatives .view-content:before {
        display: block; } }
  .pane-initiatives-initiatives .view-content h3 {
    width: 100%;
    margin: 10px 20px; }
    @media screen and (max-width: 767px) {
      .pane-initiatives-initiatives .view-content h3 {
        margin: 10px auto;
        text-align: center; } }
  .pane-initiatives-initiatives .initiative-list-item {
    max-width: 365px;
    margin: 0 auto; }
    @media screen and (min-width: 768px) {
      .pane-initiatives-initiatives .initiative-list-item {
        display: flex;
        flex-direction: column;
        margin: 0 0 30px;
        max-width: none; } }
    .pane-initiatives-initiatives .initiative-list-item .initiative-wrapper {
      position: relative; }
      .pane-initiatives-initiatives .initiative-list-item .initiative-wrapper .initiative-image-title {
        max-width: 340px;
        max-height: 250px;
        overflow: hidden;
        position: relative; }
        .pane-initiatives-initiatives .initiative-list-item .initiative-wrapper .initiative-image-title img {
          transition: all 0.3s ease 0s; }
        .pane-initiatives-initiatives .initiative-list-item .initiative-wrapper .initiative-image-title .initiative-no-image {
          max-width: 365px;
          max-height: 190px;
          width: 365px;
          height: 190px;
          display: block;
          background-color: #f7f7f7; }
        .pane-initiatives-initiatives .initiative-list-item .initiative-wrapper .initiative-image-title:hover img {
          transform: scale(1.1); }
      .pane-initiatives-initiatives .initiative-list-item .initiative-wrapper .initiative-text {
        position: relative;
        padding: 20px; }
        .pane-initiatives-initiatives .initiative-list-item .initiative-wrapper .initiative-text .initiative-title {
          margin: 0 0 10px;
          text-align: center;
          z-index: 1; }
          .pane-initiatives-initiatives .initiative-list-item .initiative-wrapper .initiative-text .initiative-title a:after {
            content: ''; }
          .pane-initiatives-initiatives .initiative-list-item .initiative-wrapper .initiative-text .initiative-title a:hover {
            text-decoration: underline; }
          .pane-initiatives-initiatives .initiative-list-item .initiative-wrapper .initiative-text .initiative-title a span {
            display: block;
            position: relative;
            top: -40px;
            margin: 0 auto -40px; }
            .pane-initiatives-initiatives .initiative-list-item .initiative-wrapper .initiative-text .initiative-title a span:before {
              content: '\e802';
              width: 50px;
              height: 50px;
              color: #e7751d;
              background: #fff;
              border-radius: 50%;
              font-size: 20px;
              text-align: center;
              font-family: "fontello";
              font-style: normal;
              font-weight: normal;
              speak: none;
              display: inline-block;
              text-decoration: inherit;
              text-align: center;
              /* opacity: .8; */
              /* For safety - reset parent styles, that can break glyph codes*/
              font-variant: normal;
              text-transform: none;
              /* fix buttons height, for twitter bootstrap */
              line-height: 1em;
              /* Animation center compensation - margins should be symmetric */
              /* remove if not needed */
              /* you can be more comfortable with increased icons size */
              /* font-size: 120%; */
              /* Font smoothing. That was taken from TWBS */
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              /* Uncomment for 3D effect */
              /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
              line-height: 50px;
              display: inline-block;
              -webkit-transition: background-color 0.25s;
              -o-transition: background-color 0.25s;
              transition: background-color 0.25s; }
        .pane-initiatives-initiatives .initiative-list-item .initiative-wrapper .initiative-text .initiative-location {
          font-weight: bold;
          text-align: center; }
          .pane-initiatives-initiatives .initiative-list-item .initiative-wrapper .initiative-text .initiative-location div {
            display: inline; }
            .pane-initiatives-initiatives .initiative-list-item .initiative-wrapper .initiative-text .initiative-location div.addressfield-container-inline:after {
              content: ', ';
              display: inline;
              visibility: visible; }
