@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_vendor-prefixes";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_variables";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_mixins";

/* =============================================================================
   ~Initiatives View
   ========================================================================== */

/**** Initiatives View ****/
.pane-initiatives-initiatives {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 20px;

  .view-content {
    @include breakpoint($sm) {
      display: flex;
      flex-wrap: wrap;
      &:before {
        display: block;
      }
    }

    h3 {
      width: 100%;
      margin: 10px 20px;
      @include breakpoint($xs) {
        margin: 10px auto;
        text-align: center;
      }
    }
  }

  .initiative-list-item {
    max-width: 365px;
    margin: 0 auto;

    @include breakpoint($sm) {
      display: flex;
      flex-direction: column;
      margin: 0 0 30px;
      max-width: none;
    }

    .initiative-wrapper {
      position: relative;
      // cursor: default;
      // background-color: $wcm-bg-gray;

      .initiative-image-title {
        max-width: 340px;
        max-height: 250px;
        overflow: hidden;
        position: relative;

        img {
          transition: all 0.3s ease 0s;
        }

        .initiative-no-image {
          max-width: 365px;
          max-height: 190px;
          width: 365px;
          height: 190px;
          display: block;
          background-color: $wcm-bg-gray;
        }

        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }

      .initiative-text {
        position: relative;
        padding: 20px;

        .initiative-title {
          margin: 0 0 10px;
          text-align: center;
          z-index: 1;

          a {
            &:after {
              content: '';
            }
            &:hover {
              text-decoration: underline;
            }
            span {
              display: block;
              position: relative;
              top: -40px;
              margin: 0 auto -40px;
              &:before {
                content: '\e802';
                width: 50px;
                height: 50px;
                color: $wcm-bright-orange;
                background: #fff;
                border-radius: 50%;
                font-size: 20px;
                text-align: center;
                @include fontello();
                line-height: 50px;
                display: inline-block;
                @include transition(background-color .25s);
              }
            }
          }
        }

        .initiative-location {
          font-weight: bold;
          text-align: center;

          div {
            display: inline;

            &.addressfield-container-inline {
              &:after {
                content: ', ';
                display: inline;
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }
}
